import Sidebar from 'component/Sidebar/sidebar'
import React from 'react'
import { Outlet } from 'react-router-dom'
import { useAgency } from 'context/agency'

function AdminLayout() {
  const { styles } = useAgency()

  const page_bg_color = 'white'

  return (
    <>
      <Sidebar />
      <main className='sm:ml-[292px] md:w-[calc(100vw-329px)]'>
        <div className='pt-5 mt-[67px]'>
          <div
            style={{
              borderRadius: '0px 0px 20px 20px ',
              minHeight: 'calc(100vh - 100px)',
            }}
            className='p-4 bg-white'
          >
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}

export default AdminLayout
